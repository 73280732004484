import { put, call, select } from 'redux-saga/effects'
import { error403, isLoading, skeleton, pageIsLoading, modalIsLoading } from 'domain/loading'
import { jobsSelector } from 'domain/recruiter/selectors'
import { transformCandidate } from 'domain/recruiter/helpers'
import I, { List } from 'immutable'
import Api from 'domain/api'
import * as Actions from './actions'
import * as M from 'domain/env'
import * as Router from 'domain/router'
import { REGIONS, updateRegion } from 'domain/candidate/helpers'

interface Params {
  order?: string
  scope?: string
  filter?: FilterType
  page?: number
  perPage?: number
}

interface FilterType {
  countryId?: number
  cityId?: number
  proximity?: number
  tagIds?: number[] | string
  rateFrom?: number
  rateTo?: number
  rateCurrency?: string
  salaryFrom?: number
  salaryTo?: number
  salaryCurrency?: string
  relocation?: string
  job?: number
  current?: string
}

export function* ensureGetCandidateProfile(props?: { payload?: boolean; type?: string } | null) {
  yield put({ type: error403, payload: false })
  const headers = yield select(M.userToken)
  const {
    params: { id }
  } = yield select(Router.matchRoutes)

  if (!headers || !id) return

  try {
    if (!props || !props.payload) yield put({ type: pageIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.getCandidateProfileR, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })
    yield put({
      type: Actions.candidateProfile.success,
      payload: updateRegion(transformCandidate(I.fromJS(data)), REGIONS)
    })
  } catch (err) {
    yield put({
      type: Actions.candidateProfile.success,
      payload: I.fromJS({})
    })
    const { statusCode } = err.response.data
    if (statusCode === 403 || statusCode === 404) {
      yield put({ type: error403, payload: true })
    }
    yield put({
      type: Actions.candidateProfile.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

type WithLoadingT = boolean | undefined

export function* ensureGetCandidates(
  props?: { payload?: Params; type?: string } | null,
  withLoading: WithLoadingT = true
) {
  const headers = yield select(M.userToken)
  if (!headers) return
  if (withLoading && (!props || !props.payload || props.payload.page === 1)) {
    yield put({ type: skeleton, payload: true })
    yield put({ type: pageIsLoading, payload: true })
  }
  const params = (props && props.payload) || {}
  try {
    const { data } = yield call(Api.getCandidatesR, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })
    const successPayload = I.fromJS({
      data: I.fromJS(data.data).map((c: any) => {
        return updateRegion(c, REGIONS)
      }),
      meta: data.meta
    })
    const page = params.page
    const total = data.meta.total
    const prevState = yield select(jobsSelector)
    if (!params || !page || page === 1 || total === prevState.size) {
      yield put({ type: Actions.candidatesNewList.success, payload: successPayload })
    } else {
      yield put({ type: Actions.candidates.success, payload: successPayload })
    }
  } catch (err) {
    yield put({
      type: Actions.candidates.failure,
      err
    })
  } finally {
    yield put({ type: skeleton, payload: false })
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureJobsToAssignedCandidate() {
  const headers = yield select(M.userToken)
  if (!headers) return
  const {
    params: { id }
  } = yield select(Router.matchRoutes)
  try {
    yield put({ type: Actions.jobsToAssignCandidate.success, payload: I.List() })
    yield put({ type: pageIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.getJobsForAssign, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })
    yield put({ type: Actions.jobsToAssignCandidate.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.jobsToAssignCandidate.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureJobsSelfAssignedCandidate() {
  const headers = yield select(M.userToken)
  if (!headers) return
  const {
    params: { id }
  } = yield select(Router.matchRoutes)
  try {
    const {
      data: { data }
    } = yield call(Api.getJobsSelfAssign, {
      headers: { Authorization: `Bearer ${headers}` },
      candidateId: id
    })
    yield put({ type: Actions.selfAssignJobCandidate.SUCCESS, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.selfAssignJobCandidate.FAILURE,
      err
    })
  } finally {
    yield put({ type: isLoading, payload: false })
  }
}

export function* ensureAssignedCandidate({
  payload
}: {
  payload: { jobs: List<number>; enableNotification: boolean }
  type: string
}) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const {
    params: { id }
  } = yield select(Router.matchRoutes)
  try {
    yield put({ type: modalIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.assignCandidateToJobs, {
      headers: { Authorization: `Bearer ${headers}` },
      id,
      data: payload
    })
    yield put({ type: Actions.assignJobToCandidate.SUCCESS, payload: I.fromJS(data) })
    yield call(ensureGetCandidateProfile, { payload: true })
  } catch (err) {
    yield put({
      type: Actions.assignJobToCandidate.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureAcceptSelfAssign({ payload }: { payload: number; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    yield put({ type: modalIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.acceptsSelfAssignedCandidateJob, {
      headers: { Authorization: `Bearer ${headers}` },
      id: payload
    })
    yield put({ type: Actions.acceptSelfAssignJobCandidate.success, payload: I.fromJS(data) })
    yield call(ensureGetCandidateProfile, { payload: true })
    yield call(ensureJobsSelfAssignedCandidate)
  } catch (err) {
    yield put({
      type: Actions.acceptSelfAssignJobCandidate.failure,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureDeclineSelfAssign({ payload }: { payload: number; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    yield put({ type: modalIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.declinesSelfAssignedCandidateJob, {
      headers: { Authorization: `Bearer ${headers}` },
      id: payload
    })
    yield put({ type: Actions.declineSelfAssignJobCandidate.success, payload: I.fromJS(data) })
    yield call(ensureGetCandidateProfile, { payload: true })
    yield call(ensureJobsSelfAssignedCandidate)
  } catch (err) {
    yield put({
      type: Actions.declineSelfAssignJobCandidate.failure,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureUnassignedCandidate() {
  const headers = yield select(M.userToken)
  if (!headers) return
  const {
    params: { id }
  } = yield select(Router.matchRoutes)
  try {
    yield put({ type: modalIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.unassignedCandidateToJobs, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })
    yield put({ type: Actions.unassignedJobToCandidate.SUCCESS, payload: I.fromJS(data) })
    yield call(ensureGetCandidateProfile, { payload: true })
  } catch (err) {
    yield put({
      type: Actions.unassignedJobToCandidate.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureAddCandidateToFavorite() {
  const headers = yield select(M.userToken)
  if (!headers) return
  const {
    params: { id }
  } = yield select(Router.matchRoutes)
  try {
    yield put({ type: pageIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.addJobToFavoriteCandidate, {
      headers: { Authorization: `Bearer ${headers}` },
      id,
      type: 'candidate'
    })
    yield put({ type: Actions.candidateFavorite.success, payload: I.fromJS(data) })
    yield call(ensureGetCandidateProfile, { payload: true })
  } catch (err) {
    yield put({
      type: Actions.candidateFavorite.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureAddCandidateToFavoriteFromList(props: {
  payload: { id: number }
  type: string
}) {
  const headers = yield select(M.userToken)
  if (!headers) return
  yield put({ type: pageIsLoading, payload: true })
  const id = props && props.payload && props.payload.id
  try {
    const {
      data: { data }
    } = yield call(Api.addJobToFavoriteCandidate, {
      headers: { Authorization: `Bearer ${headers}` },
      id,
      type: 'candidate'
    })
    yield put({
      type: Actions.candidateFavoriteFromList.success,
      payload: I.fromJS({ id, value: data })
    })
  } catch (err) {
    yield put({
      type: Actions.candidateFavoriteFromList.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureGetJobsForFilter() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const { data } = yield call(Api.getJobsRecruiterForFilter, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({ type: Actions.jobsListForFilter.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.jobsListForFilter.failure,
      err
    })
  }
}

export function* ensureGetCandidateJobs(props: {
  payload: { id?: string; params?: { status?: string; page?: number; perPage?: number } }
  type: string
}) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const { data } = yield call(Api.getRecruiterCandidateJobs, {
      headers: { Authorization: `Bearer ${headers}` },
      id: props.payload.id,
      params: props.payload.params
    })
    yield put({ type: Actions.candidateJobs.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.candidateJobs.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureApproveCandidate(props: {
  payload: { id: number; region: string }
  type: string
}) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const id = props && props.payload && props.payload.id
  const region = props && props.payload && props.payload.region
  try {
    yield put({ type: modalIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.putApproveCandidate, {
      headers: { Authorization: `Bearer ${headers}` },
      id,
      params: { region }
    })
    yield put({
      type: Actions.approveCandidate.SUCCESS,
      payload: transformCandidate(I.fromJS(data))
    })
  } catch (err) {
    yield put({
      type: Actions.approveCandidate.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}
