import { Styles } from 'react-jss'

export const sheet: Styles = {
  bigModalWidth: {},
  modalCover: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1999,
    backgroundColor: 'rgba(8, 9, 16, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modal: {
    width: '100%',
    position: 'relative',
    maxHeight: '70%',
    overflow: 'hidden',
    display: 'flex',
    '&$bigModalWidth': {
      maxWidth: '60vw'
    }
  },
  modalWidth: {
    width: 'auto'
  },
  noOverflow: {
    overflow: 'visible',
    maxWidth: '45em'
  },
  hideOverflow: {
    overflow: 'hidden',
    maxWidth: '45em'
  },
  overflowUnset: {
    overflow: 'unset'
  },
  overflow: {
    overflow: 'auto'
  },
  '@media screen and (min-width: 500px) ': {
    modal: {
      width: '100%',
      maxWidth: '33em'
    },
    modalWidth: {
      width: 'auto'
    },
    noOverflow: {
      overflow: 'visible',
      maxWidth: '45em'
    }
  },
  modalClose: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '.5em',
    lineHeight: 1,
    background: 'rgba(255,255,255,0.9)',
    border: '1px solid rgba(0,0,0,.1)',
    boxShadow: 'none',
    cursor: 'pointer',
    margin: '1.5em',
    zIndex: 2000
  },
  modalBody: {
    position: 'relative',
    width: '100%',
    backgroundColor: '#FCFCFC',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
    overflowY: ' scroll'
  },
  uLockScroll: {
    overflow: 'hidden !important'
  },
  modalBodyScroll: {
    overflowY: 'scroll',
    '&::-webkit-scrollbar': { width: 0, height: 0 }
  },
  '@media (min-width: 768px) and (max-width: 1199.98px)': {
    modal: {
      width: '90%'
    }
  }
}
