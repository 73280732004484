import { CandidateProfileType, ManagerProfileType, RecruiterProfileType } from 'types/profile'
import { ImmutableMap } from 'types/common'
import I from 'immutable'

const API_URL = process.env.REACT_APP_API_PATH_DOWNLOADS

export const REGIONS = I.fromJS({
  '519': {
    id: 519,
    name: 'United States',
    iso: 'US',
    flag: '🇺🇸'
  },
  '385': {
    id: 385,
    name: 'India',
    iso: 'IN',
    flag: '🇮🇳'
  }
})

export const transformAvatar = (data: ManagerProfileType | CandidateProfileType) => {
  return data.update('avatar', (val: string) => (val ? `${API_URL}/${val}` : undefined))
}

export const updateRegion = (data: CandidateProfileType, regions: ImmutableMap<any>) => {
  const region = data.get('regionId') ? regions.get(String(data.get('regionId'))) : undefined
  return data.set('region', region)
}

export const transformCandidate = (data: RecruiterProfileType) => {
  return data.update('avatar', (val: string) => (val ? `${API_URL}/${val}` : undefined))
}
